.Docs .content {
    width: 100%;
}

.Docs .content img {
    width: 100%;
}

.Docs .content h2,
.Docs .content h3
{
    scroll-margin: 5.5rem;
}
