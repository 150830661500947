header.header {
    background-color: var(--bs-purple);
    top: 0;
}

/* Items Menu */
header nav .navbar-toggler {
  border: none;
}

header nav .navbar-toggler .navbar-icon svg {
  width: 30px;
  height: 30px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
header nav .nav-item:hover a {
  color: #fff !important;
}
