nav.table-of-content {
    position: sticky;
    position: -webkit-sticky; 
    top: 6rem; 
    overflow: auto;
  }

/* Safari-only */
@supports (-webkit-hyphens:none) {
  nav.table-of-content h2, 
  nav.table-of-content h3 
  {
    padding-top: 16px;
    margin-top: -16px;
  }
}

nav.table-of-content a {
  color: grey;
  text-decoration: none;
}

nav.table-of-content li {
  margin: 0.5rem 0;
}
  
nav.table-of-content li.active > a,
nav.table-of-content li > a:hover
{
  color: #000;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  nav.table-of-content {
    border-bottom: 1px solid #e5e5e5;
  }
}
